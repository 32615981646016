/* Styles globaux */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashboard-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

/* Bloc 1 */
.bloc1 {
  width: 15%;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  min-height: 100vh;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  width: 60%; /* Taille r�duite du logo */
  height: auto;
}

/* Menu 1 */
.menu1 {
  list-style-type: none;
  padding: 0;
  width: 100%;
  flex-grow: 1;
  margin-bottom: 200px;
}

.menu1 li {
  padding: 10px 5px; /* Diminution de la taille du padding */
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative; /* Important pour le positionnement du sous-menu */
  font-size: 14px; /* Diminution de la taille de police */
}

.menu1 li .MuiSvgIcon-root {
  margin-right: 10px;
}

.menu1 li:hover,
.menu1 li.active {
  background-color: #E4F3F9;
  font-weight: bold;
  border-radius: 5px;
}

/* Sous-menu pour Analytics */
.submenu {
  display: none;
  position: absolute;
  top: 100%; /* Positionn� sous le menu principal */
  left: 0;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 180px;
  z-index: 1000;
}

.submenu li {
  padding: 10px;
  white-space: nowrap;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submenu li:hover {
  background-color: #E4F3F9;
}

.menu1 li:hover .submenu {
  display: block; /* Affiche le sous-menu lors du survol */
}

/* Bouton 1 et Bouton CLE */
.bouton1, .boutoncle {
  background-color: #000;
  color: #fff;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  position: sticky; /* Fix� en bas de la page, devient visible au scroll */
  bottom: 20px;
  width: 30%; /* Ajustement de la taille du bouton */
}

.bouton1:hover, .boutoncle:hover {
  background-color: #0699C1;
}

/* Bloc 2 */
.bloc2 {
  width: 85%;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  padding: 5px;
  box-sizing: border-box;
  overflow-y: auto; /* Activer le d�filement vertical */
}

/* Bloch1 pour le nom de l'administrateur et les ic�nes */
.bloch1 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 20px;
  background-color: #E1F4FA;
  padding: 5px;
  position: relative;
}

.admin-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.admin-icon {
  position: relative;
}

.admin-icon img {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.admin-menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  list-style-type: none;
  padding: 10px 0;
  margin: 0;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 150px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.admin-icon:hover .admin-menu,
.admin-icon:focus .admin-menu {
  display: block;
  opacity: 1;
}

.admin-menu a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
}

.admin-menu a:hover {
  background-color: #f0f0f0;
}

/* Ic�ne de notifications */
.notifications-icon img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

/* Bloch2 pour le texte de bienvenue */
.bloch2 {
  margin-bottom: 20px;
}

.welcome-text {
  font-size: 24px;
  color: #333;
  padding-left: 10px;
}

/* Bloch3 pour le contenu principal */
.bloch3 {
  flex-grow: 1;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
}

/* Bouton CLE sp�cifique */
.boutoncle {
  display: inline-block;
  align-items: center;
  width: 30%; /* Ajustement de la taille du bouton � 30% */
  padding: 10px; /* Ajustement du padding pour respecter l'espace int�rieur */
}

.boutoncle:hover {
  background-color: #0699C1;
}

/* Styles pour bloch3home, bloch3le et bloch3pl */
#bloch3home, #bloch3le, #bloch3pl {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bloch3ple, .bloch3ppl, .bloch3ue, .bloch3oe, .bloch3prl, .bloch3ueprivate, .bloch3oeprivate {
  background-color: #e1f4fa;
  color: black;
  width: 95%;
  height: 17%;
  padding: 3px;
}

.bloch3total {
  background-color: #75B8FF;
  color: white;
  height: 30%;
}

.titretn, .titreue, .titreoe, .titreueprivate, .titreoeprivate {
  color: blue;
  text-align: left;
  font-size: 18px;
}

/* Styles pour le popupnle */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupnle {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 100%;
}

.popup-title {
  color: blue;
  font-size: 20px;
  margin-bottom: 10px;
}

.formnle label {
  display: block;
  margin-top: 10px;
}

.formnle input,
.formnle select {
  width: 100%;
  margin-top: 5px;
  padding: 10px;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.popup-delete, .popup-publish, .popup-close {
  background-color: #000;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.popup-delete:hover, .popup-publish:hover, .popup-close:hover {
  background-color: #0699C1;
}

/* Ajout d'une fl�che retour dans le popup */
.popupnle button {
  background: none;
  border: none;
  cursor: pointer;
  color: blue;
  font-size: 18px;
}

/* Styles pour le carrousel horizontal */
.carousel-container {
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}

.carousel {
  display: inline-flex;
  gap: 10px;
}

.live-event {
  display: inline-block;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.event-date, .event-time, .vendor-name, .product-count {
  margin: 5px 0;
}

.vendor-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.vendor-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}