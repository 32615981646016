/* Variables CSS pour les couleurs et le design */
:root {
    --primary-color: #4084F4;
    --text-color: #000;
    --background-color: #FFF;
    --border-radius: 10px;
    --accent-color: #5C5C5C;
}

/* Style global */
body, .container, .bloc, .blocchat, .blocvideo, .blocproduit {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

body {
    background-color: var(--background-color);
    overflow: hidden;
    padding: 5px;
}

.container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 10px);
    gap: 10px;
}

.bloc {
    padding: 10px;
    box-sizing: border-box;
    border-radius: var(--border-radius);
    overflow: hidden;
    position: relative;
}

/* Bloc des commentaires */
.blocchat {
    position: relative;
    background-image: linear-gradient(to top, #F0F0F0 0%, #DADADA 100%);
    color: var(--text-color);
    width: 27.5%;
    z-index: 1;
}

/* Bloc de la vid�o diffus�e */
.blocvideo {
    position: relative;
    overflow: hidden;
    background-color: #222222;
    width: 45%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

/* Barre de progression */
#progressBarContainer {
    width: 70%;
    height: 7px;
    background-color: #DADADA;
    position: absolute;
    top: 3px;
    left: 15%;
    overflow: hidden;
    z-index: 3;
    border-radius: 5px;
}

#progressBar {
    height: 7px;
    background-color: var(--accent-color);
    width: 0%;
    transition: width 0.5s ease-out;
    z-index: 4;
    border-radius: 5px;
}

/* Bloc des produits */
.blocproduit {
    background-color: #F1F1F1;
    width: 27.5%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

/* M�dia Queries pour petits �crans */
@media (max-width: 767px) {
    .container {
        flex-direction: column;
    }
    .blocchat, .blocproduit {
        display: none;
    }
    .mobile-overlay {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: flex-end;
        pointer-events: none;
    }
    .overlay-chat, .overlay-product {
        width: 100%;
        height: 20%;
        overflow-y: auto;
        padding: 10px;
        box-sizing: border-box;
        pointer-events: auto;
    }
}
